<template>

  <!-- <transition name="fade">
      <div v-if="showPropertyDialog" class="dialog-box info" >

        <entry-descr :noTitle="false" :title="entryActive.jobNo" :leftSide="false" @closePropertyDialog="closePropertyDialog()" >
            <template v-slot:modalBody>
              <div class="vuecal__event-title job-name" v-html="entryActive.jobName"  />
              <div class="vuecal__event-title job-client" v-html="entryActive.client" />

              <div class="vuecal__event-time">
                <div class="job-time">
                  <button class="i-btn" @click="closePropertyDialog()" title="Edit"><i class="fas fa-hourglass-start"></i></button>
                  <span><strong>{{ convertToHM(entryActive.start) }} - {{ convertToHM(entryActive.end) }}</strong></span>
                </div>
              </div>
              <div class="d-buttons">
                <button class="d-btn" @click="entiresDelete(entryActive.id)" title="Delete"><i class="fas fa-trash"></i></button>
                <button class="d-btn" @click="closePropertyDialog()" title="Close"><i class="far fa-window-close"></i></button>
              </div>
            </template>
        </entry-descr>
      </div>
  </transition> -->



  <base-dialog :windowWidth="0" :show="showPropertyDialog" title="ENTRY DETAILS" @close="closePropertyDialog()">
     <template v-slot:content>
      <div class="event-wrap">

        <div class="vuecal__event-title job-name" v-html="entryActive.jobName"  />
        <div class="vuecal__event-title job-client" v-html="entryActive.client" />

        <div class="vuecal__event-time">
          <div class="job-time">
            <button class="i-btn" @click="closePropertyDialog()" title="Edit"><i class="fas fa-hourglass-start"></i></button>
            <span><strong>{{ convertToHM(entryActive.start) }} - {{ convertToHM(entryActive.end) }}</strong></span>
          </div>
          <!-- <div class="job-time">
            <i class="fas fa-hourglass-end"></i>&nbsp;&nbsp;<span>{{ selectedEvent.start.format("YYYY-MM-DD") }} <strong>{{ selectedEvent.end.formatTime("HH:mm") }}</strong></span>
          </div> -->
        </div>      

      </div>
    </template>

    <template v-slot:actions>

        <base-button class="d-btn d-edit" @click="closePropertyDialog()" title="Close"><i class="fas fa-times"></i></base-button>
        <base-button class="d-btn d-edit" @click="entiresDelete(entryActive.id)" title="Delete"><i class="fas fa-trash"></i></base-button>        

      
    </template>
  </base-dialog>





  <base-dialog :windowWidth="0" :show="newEntryDialog" title="NEW ENTRY" @close="closeNewEntryDialog()">
     <template v-slot:content>
      <div class="s-label">
        
        <span v-if="newEntry.noJob">Job description</span>
        <span v-else>Job number</span>
        <div class="no-job-wrapper light-text">
          <div class="info-text">unknown Job number</div>
          
          <label class="rad-label">
                <input type="checkbox" class="rad-input" name="noJob" v-model="newEntry.noJob" />
                <div class="rad-design"></div>
         </label>
        </div>

      </div>

      <div v-if="newEntry.noJob">
        <div class="no-job-form-wrapper">
          <textarea placeholder="description of the work performed" v-model="newEntry.noJobDescr"></textarea>
          <vue-select
            v-model="newEntry.noJobUserId"
            :options="usersListGet()"
            label-by="Name"
            value-by="Id"
            placeholder="select a responsible person"
            searchable
            clear-on-select
            clear-on-close
            close-on-select
          >
              <template #dropdown-item="{ option }">
                <div :class="'st-'+option.Status">{{ option.Name }}</div>
              </template>
          </vue-select>

        </div>
        
      </div>
      <div v-else>

        <div v-if="jobExistCheck(newEntry.jobId) || newEntryDialog">
          <vue-select
            v-model="newEntry.jobId"
            :options="jobs"
            label-by="jobNoName"
            value-by="jobId"
            placeholder="select Job number"
            searchable
            clear-on-select
            clear-on-close
            close-on-select
            ref="newEntry"
          >
                <template #dropdown-item="{ option }">
                  <div :class="this.$store.getters.getCurrYear == option.jobYear ? 'y-active':'y-inactive'">{{ option.jobNoName }}</div>
                </template>

          </vue-select>
        </div>
        <div v-else class="job-expired">
          <p>{{ newEntry.job }}<br/><span>/expired/</span></p>
        </div>

      </div>


      

      <div class="valid-content">
          <span>
             {{ warningMessageEntryDialog }}
             <!--{{ selectedEvent.noJobDescr }}<br> -->
          </span>
      </div>
    </template>

    <template v-slot:actions>
      <base-button class="d-btn d-edit" @click="closeNewEntryDialog()" title="Cancel"><i class="fas fa-times"></i></base-button>
      <base-button class="d-btn d-edit" @click="saveNewEntryDialog()"
          title="Save"
          :disabled="warningMessageEntryDialog !=='' || checkDisable(newEntry.noJob, newEntry.jobId, newEntry.noJobUserId, newEntry.noJobDescr)"
      ><i class="fas fa-save"></i></base-button>
    </template>
  </base-dialog>

  
    <nav-header :showNav="true" :clearButtons="true">
        <template v-slot:navButtons>  
            <button class="nav-btn" @click="today()">Today</button>
            <button class="nav-btn" @click="previous()"><i class="angle prev"></i></button>
            <button class="nav-btn" @click="next()"><i class="angle"></i></button> 
        </template>
        
        <template v-slot:navView>
            <h3>Calendar</h3>
        </template>      
      
        <template v-slot:navLog>
            <button class="nav-btn" @click="showM()"><i :class="[showMenu ? 'fa-ellipsis-v':'fa-ellipsis-h', 'fas']"></i></button>
        </template>
    </nav-header>

    <swipe-area @swipe-left="onSwipeLeft" @swipe-right="onSwipeRight">
      <div class="main-mobile-wrap">

        <date-picker v-model="selectedDate" 
              @update:model-value = "entiresListRefresh(this.selectedDate)"
              @open = "this.showPropertyDialog = false;"
              :clearable="false"          
              :enableTimePicker="false"
              :inline="false"
              auto-apply
              :offset="36"
              >

                <template #input-icon>
                          &nbsp;&nbsp;<i class="far fa-calendar-alt"></i>
                </template>

        </date-picker>


        <div :class="['main-date', getDayName2(selectedDate) == 'Saturday' || getDayName2(selectedDate) == 'Sunday' ? 'weekend' : '']">
          <button class="btn-tr " @click="previous()"><i class="fas fa-angle-left"></i></button>
          {{selectedDate.format()}}
          <button class="btn-tr" @click="next()"><i class="fas fa-angle-right"></i></button>
          <p :class="['day-name', getDayName2(selectedDate) == 'Saturday' || getDayName2(selectedDate) == 'Sunday' ? 'weekend' : '']">{{getDayName2(selectedDate)}}</p>
        </div>
        <div class="main-sum">{{selectedDateSum}}</div>

        <div class="cal-wrap">
          <ul class="cal-list">
            <li class="list-title">
              <div>
                <button class="btn-tr" @click="sort('jobNo')">Job No</button>
              </div> 
              <div>
                <button class="btn-tr" @click="sort('start')">Start</button>
              </div> 
              <div>
                <button class="btn-tr" @click="sort('end')">End</button>
              </div> 
              <div>
                <button class="btn-tr" @click="sort('time')">Time</button>
              </div>
            </li>
            <li v-for="entry in entries" :key="entry.id">
              
              <div :class="['job-item', 'vuecal__event', entry.class]"><button class="btn-tr btn-ul" @click="showDetails(entry.id)">{{ entry.jobNo }}</button></div> 
              <div>
                <button class="btn-tr" @click="entryEdit(entry.id, 'start', 'minus')"><i class="fas fa-angle-up"></i></button>
                <p>{{ convertToHM(entry.start) }}</p>
                <button class="btn-tr" @click="entryEdit(entry.id, 'start', 'plus')"><i class="fas fa-angle-down"></i></button>
              </div> 
              <div>
                <button class="btn-tr" @click="entryEdit(entry.id, 'end', 'minus')"><i class="fas fa-angle-up"></i></button>
                <p>{{ convertToHM(entry.end) }}</p>
                <button class="btn-tr" @click="entryEdit(entry.id, 'end', 'plus')"><i class="fas fa-angle-down"></i></button>
              </div> 
              <div class="time-item">{{ entry.time }}</div>
              <button class="btn-ab-item btn-tr btn-del" @click="entiresDelete(entry.id)"><i class="far fa-minus-square"></i></button>
            </li>
          </ul>

          
          <button class="btn-tr btn-add" @click="newEntryDialog = true"><i class="far fa-plus-square"></i></button>
          
        </div>

      </div>

    </swipe-area>

    <nav-menu :showMenu="showMenu" ></nav-menu>
  
</template>

<script>
import entiresService from "@/services/entries.js";
import userService from "@/services/user.js";
import jobsService from "@/services/jobs.js";
import shared from "@/shared";
import moment from "moment";
//import calendar from "../components/Calendar.vue";

export default {

  name: "App",

 data: function () {
    return {
      loader: false,
      error: null,
      showMenu: false,
      selectedDate: new Date(),    
      selectedDateSum: '00:00',
      settDarkMode: false,
      jobs: [],
      entries: [],
      entriesDataSum: [],
      entriesOrder: 'asc',
      activeSort: '',
      entryActive: {},
      showPropertyDialog: false,
      newEntryDialog: false,
      warningMessageEntryDialog: '',
      warningMessageCreationDialog: '',
      newEntry: {
        jobId: null,
        jobName: null,
        noJob: false,
        noJobId: null,
        noJobUserId: null,
        noJobDescr: null
      },
      usersList: [],

    };
  },
  
  created() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.setTheme = shared.setTheme;
    this.convertToHM = shared.convertToHM;
    this.convertToYMDHM = shared.convertToYMDHM;
    this.getDayName2 = shared.getDayName2;
    
    this.CookieCheckGoToLogin();
  },

  mounted() {
    this.addBodyClass('mobile');

    userService
      .usersList()
      .then((response) => {

        if (response.status === 200) {
          this.usersList = response.data;
          // console.log("----user list----");
          // console.log(this.usersList);
        }

      })
      .catch((e) => shared.handleError.call(this, '011', true, e));

    jobsService
      .jobs()
      .then((response) => {

        if (response.status === 200) {

          this.jobs = response.data;

          // console.log("----this.jobs----");
          // console.log(this.jobs);

        }

      })
      .catch((e) => shared.handleError.call(this, '010', true, e));

    this.entiresListRefresh(this.selectedDate);
  },

  unmounted() {
    this.removeBodyClass('mobile');
  },

  methods: {

    onSwipeLeft() {
      console.log('Wykryto przesunięcie w lewo');
      this.next();
    },
    onSwipeRight() {
      console.log('Wykryto przesunięcie w prawo');
      this.previous();
    },

    entiresList(data) {
        // console.log('entiresList');
        // console.log(data);
      entiresService
        .list(data)
        .then((response) => {
          if (response.status == "200") {
            this.entries = response.data;
            this.entries = this.sortObjects(this.entries, 'start', 'asc');
              // console.log('entiresList');
              // console.log(this.selectedDate.format("YYYY-MM-DD HH:mm"));
              // console.log(this.entries);
              
            
          }
        })
        .catch((e) => shared.handleError.call(this, '025', false, e));
    },

    entiresDataSum(data) {

      entiresService
        .dateSum(data)
        .then((response) => {
          if (response.status == "200") {
            this.entriesDataSum = response.data;
            //console.log(this.entriesDataSum);

            let foundEvent = this.entriesDataSum.find(event => event.start === data.startDate);
            this.selectedDateSum = '00:00';
            if (foundEvent) {
              this.selectedDateSum = foundEvent.time;
            }

          }
        })
        .catch((e) => shared.handleError.call(this, '024', false, e));
    },

    entiresDelete(entriesId) {


      entiresService
        .delete(entriesId)
        .then((response) => {
          if (response.status == "200") {         

            this.entiresListRefresh(this.selectedDate);
            this.showPropertyDialog = false;
          }
        })
        .catch((e) => shared.handleError.call(this, '028', false, e));
    },

    entiresListRefresh(date) {

      let data = {
        view: 'week',
        startDate: date.format("YYYY-MM-DD"),
        endDate: date.format("YYYY-MM-DD"),
      };

      this.entiresList(data);
      this.entiresDataSum(data);  
    },

    entryEdit(id, type, action) {

      const foundItem = this.entries.find(item => item.id === id);
      let date = null;

      if (type =='start') {
        date = new Date(foundItem.start);
      }
      if (type =='end') {
        date = new Date(foundItem.end);
      }
      if (action =='minus') {
        date.setMinutes(date.getMinutes() - 30);
      } 
      if (action =='plus') {
        date.setMinutes(date.getMinutes() + 30);
      } 


      const entry = {
          id: id,
          start: type =='start' ? moment(date).format("YYYY-MM-DD HH:mm") : moment(foundItem.start).format("YYYY-MM-DD HH:mm"),
          end: type =='end' ?  moment(date).format("YYYY-MM-DD HH:mm") : moment(foundItem.end).format("YYYY-MM-DD HH:mm"),
          jobId: foundItem.jobId,
          noJobId: foundItem.noJobId,
          noJobUserId: foundItem.noJobUserId,
          noJobDescr: foundItem.noJobDescr,
          noJobChangeAll: 0
        };

        console.log("--------ACTION ------------");
        console.log(entry);


        entiresService
        .edit(entry)
        .then((response) => {
          //console.dir(response);
          if (response.status === 200) {
            if (response.data.Code === "OK") {

              console.log("--------OK ------------");
              



              setTimeout(() => {



                entiresService
                  .get(id)
                  .then((response2) => {

                    if (response2.status === 200) {

                      let idx = this.entries.findIndex((x) => x.id == id);

                      console.log('idx:');
                      console.log(idx);

                      if (idx >= 0) {

                          this.entries[idx].start = new Date(response2.data.start).format("MM/DD/YYYY HH:mm:00");
                          this.entries[idx].end = new Date(response2.data.end).format("MM/DD/YYYY HH:mm:00");
                          this.entries[idx].class = response2.data.class;
                          this.entries[idx].client = response2.data.client;
                          this.entries[idx].job = response2.data.job;
                          this.entries[idx].jobId = response2.data.jobId;
                          this.entries[idx].jobNo = response2.data.jobNo;
                          this.entries[idx].jobName = response2.data.jobName;
                          this.entries[idx].time = response2.data.time;
                          this.entries[idx].noJobId = response2.data.noJobId;
                          this.entries[idx].noJobUserId = response2.data.noJobUserId;
                          this.entries[idx].noJobDescr = response2.data.noJobDescr;



                          const data = {
                            view: 'week',
                            startDate: date.format("YYYY-MM-DD"),
                            endDate: date.format("YYYY-MM-DD"),
                          };
                          this.entiresDataSum(data);

                      }

                    }
                  })
                  .catch((e) => shared.handleError.call(this, '029', false, e));

              }, 69);


            }
            else {
              this.error = "(030) "+response.data.Text;
              setTimeout(() => {
                this.reloadPage();
              }, 600);
            }
          }
        })
        .catch((e) => shared.handleError.call(this, '031', false, e));

    },
    

    addBodyClass(className) {
      document.body.classList.add(className);
    },

    removeBodyClass(className) {
      document.body.classList.remove(className);
    },

    today() {
      try {
        this.showPropertyDialog = false;
        this.selectedDate = new Date();
        this.entiresListRefresh(this.selectedDate);

      } catch (error) {
        console.log("--------ERROR ------------");
        console.dir(error);
      }

    },

    previous() {
      try {
        this.showPropertyDialog = false;
        this.selectedDate = new Date(this.selectedDate.getTime()).addDays(-1);
        this.entiresListRefresh(this.selectedDate);

      } catch (error) {
        console.log("--------ERROR ------------");
        console.dir(error);
      }
    },

    next() {
      try {
        this.showPropertyDialog = false;
        this.selectedDate = new Date(this.selectedDate.getTime()).addDays(1);
        this.entiresListRefresh(this.selectedDate);
      } catch (error) {
        console.log("--------ERROR ------------");
        console.dir(error);
      }
    },

    sortObjects(array, key, order = 'asc') {
      return array.sort((a, b) => {
          let comparison = 0;
          
          if (key === 'start' || key === 'end') {
              // Parse dates for comparison
              comparison = new Date(a[key]) - new Date(b[key]);
          } else if (key === 'time') {
              // Assuming 'time' is in "HH:MM" format, convert to minutes for comparison
              const timeToMinutes = (time) => {
                  const [hours, minutes] = time.split(':').map(Number);
                  return hours * 60 + minutes;
              };
              comparison = timeToMinutes(a[key]) - timeToMinutes(b[key]);
          } else {
              // Default string comparison
              comparison = a[key].localeCompare(b[key]);
          }
          
          // Adjust for descending order
          return order === 'desc' ? -comparison : comparison;
      });
    },

    sort(key) {
      this.entries = this.sortObjects(this.entries, key, this.entriesOrder);
      this.activeSort = key;
      
      this.entriesOrder = this.entriesOrder === 'asc' ? 'desc' : 'asc';
      
    },

    showDetails(entryId) {

      this.entryActive = {};
      this.entryActive = this.entries.find(event => event.id === entryId);
      this.showPropertyDialog = true;

      console.log("--------entryActive ------------");
        console.log(this.entryActive);
    },

    closePropertyDialog() {
      this.showPropertyDialog = false;
    },

    closeNewEntryDialog() {
      this.newEntryDialog = false;
    },

    showM() {
      this.showPropertyDialog = false;
      this.showMenu = !this.showMenu;

    },

    checkDisable(nojob, jobid, nojobuser, nojobdescr) {

      if (nojob && (nojobuser === 0 || nojobuser === null || nojobdescr == null || nojobdescr == '')) {
        return true;
      }
      if (!nojob && (jobid === 0 || jobid === null)) {
        return true;
      }

      return false;
    },

    usersListGet() {
      let newArr = [];

      this.usersList.forEach(element => {
         newArr.push({Id: element.Id, Name: element.FirstName+' '+element.LastName, Status: element.Status});
      });

      return newArr;
    },

    jobExistCheck(jobId) {

      if (this.jobs.filter(e => e.jobId === jobId).length > 0) {
        return true
      }
      else return false;

    },




    saveNewEntryDialog() {
      // console.log("closeCreationDialog - selectedEvent");
      // console.log( this.selectedEvent);

      if (this.newEntry.noJob) {
            this.newEntry.jobId = 0;
      }


      if (this.newEntry.jobId === null && (this.newEntry.noJobUserId === 0 || this.newEntry.noJobUserId === null) ) {
        this.warningMessageCreationDialog = 'Honey, first you should select Job number';
      }
      else {
        this.warningMessageCreationDialog = '';

        // Funkcja, która znajdzie obiekt z najpóźniejszą datą i godziną w polu `end`
        let objects = []; 
        let startTime = null;
        let endTime = null; 

        objects = this.entries;

        if (objects.length > 0) {

          const findLatestEnd = (objects) => {
              return objects.reduce((latest, current) => {
                  return new Date(current.end) > new Date(latest.end) ? current : latest;
              });
          };

          // Znalezienie obiektu z najpóźniejszym `end`
          const latestObject = findLatestEnd(objects);

          // Wyciągnięcie wartości `id`, `start` i `end`
          const { id, start, end } = latestObject;

          console.log(`ID: ${id}, Start: ${start}, End: ${end}`); 

          let startTmp = new Date(end);
          let endTmp = new Date(end);

          startTime = startTmp;
          endTime = endTmp.setHours(endTmp.getHours() + 1);       

        } 
        else {         

          let startTmp = new Date(this.selectedDate);
          let endTmp = new Date(this.selectedDate);


          startTime = startTmp.setHours(9, 0, 0, 0);
          endTime = endTmp.setHours(10, 0, 0, 0); 
          
        }


        const entry = {
          start: this.convertToYMDHM(startTime),
          end: this.convertToYMDHM(endTime),
          jobId: this.newEntry.jobId,
          repeat: 0,
          repeatWeek: 0,
          noJobId: this.newEntry.noJobId,
          noJobUserId: this.newEntry.noJobUserId,
          noJobDescr: this.newEntry.noJobDescr
        };

        console.log('entry');
        console.log(entry);

            entiresService
              .add(entry)
              .then((response) => {

                if ((response.status === 200) & (response.data.Code === "OK")) {
                 

                  const dataRes = JSON.parse(response.data.Result);

                  // dataRes.length
                  console.log('dataRes------');
                  console.log(dataRes);

                  this.newEntry = {
                    jobId: null,
                    jobName: null,
                    noJob: false,
                    noJobId: null,
                    noJobUserId: null,
                    noJobDescr: null
                  };

                  this.newEntryDialog = false;


                  setTimeout(()=>{
                    this.entiresListRefresh(this.selectedDate);
                  },100);

                }
                else {

                  this.error = "(017) "+response.data.Text;

                }
              })
              .catch((e) => shared.handleError.call(this, '018', false, e));
   
      }
    },






  }
};
</script>

<style>


  body.mobile {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    width: 100%;
    max-width: 680px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .mobile .dialog-box {
    position: absolute;

    background-color: #fff8e3;
    width: 80%;
    min-width: 316px;
    max-width: 480px;
    min-height: 150px; 
    left: 50%;  
    top: 50%;  
    z-index: 100;

    -webkit-box-shadow: 5px 5px 9px -3px rgba(164, 165, 183, 1);
    -moz-box-shadow: 5px 5px 9px -3px rgba(164, 165, 183, 1);
    box-shadow: 5px 5px 9px -3px rgba(164, 165, 183, 1);

    transform: translate(-50%, -50%); 
  }


  .mobile .dialog-box .d-buttons {
    margin-top: 10px;
  }



  .mobile .invalid-wrap {
    display: none;
  }


  body.mobile .nav-btn {
    padding: 6px 9px 6px;
    font-size: 12px;
    line-height: 10px;
    min-width: 32px;
  }


  .mobile .main-date {
    margin-top: 16px;
    font-family: 'Anton-Regular';
    font-size: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile div.weekend,
  .mobile p.weekend {
    color: #c25a10;
  }

  .mobile .main-date .day-name {
    position: absolute;
    top: -12px;
    right: 14px;
    margin: 0;
    padding: 0;
  }

  .mobile .main-date button  {
    padding: 0px 10px;
  }

  .mobile .main-date button i {
    font-size: 18px;
  }


  .mobile .main-sum,
  .mobile .main-date .day-name {
    font-family: 'Lato-Bold';
    font-size: 14px;
    color: #aaa;
  }


  .mobile .cal-list {
   padding: 12px;
   margin-top: 24px;
   margin-bottom: 0;
  }
  
  .mobile .cal-list li {
    display: flex;
    align-items: center;
    padding: 6px 0;
    
    color: #667;
    border-bottom: 1px solid #e3e3e3;
    position: relative;
  }

  .mobile .cal-list li.list-title {
    padding-bottom: 10px;    
    border: 1px solid #bcbcbc;
    background-color: #e1e1e1;
  }

  .mobile .cal-list li.list-title button {   
    color: #000;
    font-family: 'Lato-Bold';
  }

  .mobile .cal-list li div:first-of-type {
    width: 40%;
  }

  .mobile .cal-list li div:not(:first-of-type) {
    width: 20%;
  }

  .mobile .cal-list li .time-item {
    color: #2c3e50;
    font-family: 'Lato-Bold';
  }

  .mobile .cal-list li .job-item {
    padding: 14px 0;
    line-height: 14px;
  }

  .mobile .cal-list li .btn-ab-item {
    position: absolute;
    right: -6px;
    bottom: 0;
    font-size: 15px;
  }

  

  .mobile .cal-list li div p {
    margin: 0;
    font-size: 14px;
  }

  .mobile .btn-tr {
    border: none;
    background: transparent;
  }


  .mobile .btn-ul {
    font-family: 'Lato-Bold';
    color: #2c3e50;
    text-decoration: underline;
  }

  .mobile .cal-wrap .btn-add {
    font-size: 24px;
    color: #2c3e50;
    margin-bottom: 24px;
  }
  
  .mobile .main-mobile-wrap .dp__main {
    z-index: 2;
  }
  .mobile .main-mobile-wrap input.dp__input {
    display: none;
  }

  .mobile .main-mobile-wrap .dp__input_icon {
    margin-top: -4px;
    margin-left: 2px;
  }

  .mobile dialog section .no-job-form-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
  }

  .mobile dialog.dialog-box section .no-job-form-wrapper .vue-select, 
  .mobile dialog.dialog-box section .no-job-form-wrapper textarea {
    width: 100%;
    margin: 12px 0;
    box-sizing: border-box;
  }

  .mobile dialog.dialog-box section .no-job-form-wrapper textarea {
    min-height: 100px;
  }

  .mobile .event-wrap {
    text-align: center;
  }

  .mobile .event-wrap .job-client {
    margin-top: 18px;
    font-size: 14px;
  }

  .mobile .event-wrap .job-name {
    margin-top: 0;
    font-size: 18px;
  }

  .mobile .event-wrap .job-time strong {
    font-size: 13px;
  }
  

</style>